import React, { Component } from 'react';

import './App.css';
import Base from './templates/base'



class App extends Component {
  render() {
    return (
      <div className="odieworks_app">
        <Base />
      </div>
    );
  }
}

export default App;
