import React, { Component } from 'react';

import './index.css';
import Canvas from '../Canvas';
import Menu from '../Menu';

class Body extends Component {
  constructor(props)
  {
    super(props);

 
  }



  componentDidMount() 
  {

  }





  render() {

    return (
      <div className="odieworks_body">

        <div className='rjh-body-subcomponent rjh-body-subcomponent-left'>
          <Menu />
        </div>

        <div className='rjh-body-subcomponent rjh-body-subcomponent-right'>
          <Canvas>
            {this.props.children}
          </Canvas>
        </div>

      </div>
    );
  }
}

export default Body;
