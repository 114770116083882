import React, { Component } from 'react';

import './base.css';

import Main from '../components/Main'

import RJHBS from './rjh-base-site'

class Base extends Component {

  constructor(props)
  {
    super(props);


    this.state =
    { 

    }
  }

  componentDidMount()
  { 

  }


 
  render() {
    return (
      <div id="base_template">

        <RJHBS>
          <div>

            <Main />

          </div>
        </RJHBS>

      </div>
    );
  }
}

export default Base;
