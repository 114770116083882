// RJHBS
//
//  desc:
//         
//
//
//  props:
//

import './index.css';

import Header from './Header'
import Footer from './Footer'
import Body from './Body'


import React from 'react';
class RJHBS extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {

    };
  }


  render()
  {
    return(
      <div className='rjh-base-site'>
        
        <div className='owgs_template_bookends'>   
          <div id='owgs_template_header'>
            <Header />
          </div>
        </div>

        <Body>
          {this.props.children}
        </Body>

        <div className='owgs_template_bookends'>
          <div id='owgs_template_footer'>
            <Footer />
          </div>
        </div>

      </div>
    );
  }

}

export default RJHBS;
