// Menu
//
//  desc:
//         
//
//
//  props:
//

import React from 'react';
import './index.css'

class Menu extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {

    };
  }


  render()
  {
    return(
      <div  className='rjh-menu'>

        <div className='rjh-link'>
          <a href='/2015_vday'>Valentine's Day 2015</a>
        </div>
        <div className='rjh-link'>
          <a href='/2013_vday'>Valentine's Day 2013</a>
        </div>
        <div className='rjh-link'>
          <a href='/2012_vday'>Valentine's Day 2012</a>
        </div>

      </div>
    );
  }

}

export default Menu;
