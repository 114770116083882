// Main
//
//  desc:
//         
//
//
//  props:
//

import React from 'react';
import './index.css'

import lovesLogo from '../../../resources/images/lovesLogo.png'

class Main extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {

    };
  }


  getOldHtml()
  {
    let oldHtml = 
      <div>
        <div id="newest">
          <img src={lovesLogo} alt="Smiley face" height="600" width="800" />
        </div>
        <div className="rjh-2015-vday-subtitle" id="">
          The Game that shows that I love you more! 
        </div>
      </div>

    return oldHtml
  }


  render()
  {
    let oldHtml = this.getOldHtml();
    return(
      <div>
        
        {oldHtml}
      
      </div>
    );
  }

}

export default Main;