// Canvas
//
//  desc:
//         
//
//
//  props:
//

import React from 'react';
class Canvas extends React.Component
{
 constructor(props)
  {
    super(props)
    this.state = {

    };
  }


  render()
  {
    return(
      <div>
        
        {this.props.children}
        
      </div>
    );
  }

}

export default Canvas;